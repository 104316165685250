import React from "react"
import { graphql } from "gatsby"
import { Header, Footer, Aside } from "../components"
import SEO from "../components/seo"
import {
  ShortPostWithoutExcerpt,
  TagH1,
  DividerBottom,
  Wrapper,
  Left,
  PostsWrapper,
  Posts,
  AsideWrapper,
  TitleWrapper,
  DividerTop,
} from "../elements"
import { GlobalStyle } from "../../src/elements/index"
import { tagMapper } from "../utils/tools"

const Category = ({ data, location }) => {
  const byTag = location.pathname.split("/")[1] === "category"
  const year = location.pathname.split("/")[1]
  const month = location.pathname.split("/")[2]
  const tag = location.pathname.split("/")[2]
  const edges = byTag ? data.byTag.edges : data.byTime.edges
  const tagName = tagMapper(tag)

  return <>
    <SEO
      title={tagName}
      description={
        byTag ? `日常小事-${tagName}` : `在 ${year}年${month}發生的事`
      }
    />
    <GlobalStyle />
    <Header />
    <div>
      <TitleWrapper>
        <DividerTop />
        <TagH1>{byTag ? tagName : month + " 月 " + year}</TagH1>
        <DividerBottom />
      </TitleWrapper>
      <Wrapper>
        <Left>
          <PostsWrapper>
            {edges.length > 0 ? (
              edges.map(edge => {
                const post = edge.node.frontmatter
                const date = new Date(post.date)
                const img = post.featureImage.childImageSharp.gatsbyImageData
                return (
                  <Posts key={post.title}>
                    <ShortPostWithoutExcerpt
                      image={img}
                      post={post}
                      date={date}
                    />
                  </Posts>
                )
              })
            ) : (
              <></>
            )}
          </PostsWrapper>
        </Left>
        <AsideWrapper>
          <Aside />
        </AsideWrapper>
      </Wrapper>
    </div>
    <Footer />
  </>;
}

export const query = graphql`query TagQuery($tag: String!, $gte: Date!, $lte: Date!) {
  byTag: allMdx(filter: {frontmatter: {tag: {eq: $tag}, type: {eq: "post"}}}) {
    edges {
      node {
        frontmatter {
          date
          slug
          tag
          title
          featureImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  byTime: allMdx(
    filter: {frontmatter: {date: {gte: $gte, lte: $lte}, type: {eq: "post"}}}
  ) {
    edges {
      node {
        frontmatter {
          date
          slug
          tag
          title
          featureImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}`

export default Category
